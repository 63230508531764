import type { PopulatedMenu, PopulatedSection } from '../../types/menusTypes';

export type SectionList = Record<
  PopulatedSection['id'],
  {
    menuId: PopulatedMenu['id'];
    name: PopulatedSection['name'];
    prevSectionId: PopulatedSection['id'];
    nextSectionId: PopulatedSection['id'];
    isInViewPort: boolean;
    index: number;
  }
>;

export const initSectionsList = (menus: PopulatedMenu[]): SectionList => {
  const allSectionIds = menus.flatMap((menu) => menu.sections?.flatMap(({ id }) => id));
  let index = 0;
  return menus.reduce((acc, menu) => {
    const menuSections = menu.sections.reduce((sectionMap, section) => {
      const prevSectionId = index > 0 ? allSectionIds[index - 1] : '';
      const nextSectionId = index < allSectionIds.length - 1 ? allSectionIds[index + 1] : '';
      index++;
      return {
        ...sectionMap,
        [section.id as PopulatedSection['id']]: {
          menuId: menu.id,
          name: section.name,
          prevSectionId,
          nextSectionId,
          index,
          isInViewPort: false,
        },
      };
    }, {} as SectionList);
    return { ...acc, ...menuSections };
  }, {} as SectionList);
};

import type { TFunction } from '@wix/yoshi-flow-editor';
import { ItemModalController } from './itemModalController';
import model from './model';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import type { PopulatedMenu } from 'root/types/menusTypes';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import { getSiteCurrency, getSiteLocale } from 'root/utils/siteDataUtils';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { PopulateMenuIdsByOperationClient } from 'root/api/PopulateMenuIdsByOperationClient';
import { convertToRepeaterModifierGroups } from 'root/utils/itemModalControllerUtils';
import { getTimezoneOffset } from 'root/api/utils/utils';
import { OperationsClient } from 'root/api/operationClient';

export default model.createController(({ $w, $bindAll, $widget, flowAPI }) => {
  const {
    controllerConfig,
    translations,
    experiments,
    reportError,
    environment,
    httpClient,
    errorMonitor,
  } = flowAPI;

  const { wixCodeApi } = controllerConfig;
  const window = wixCodeApi.window;
  const { isEditor, isPreview } = environment;
  const lightboxContext = window.lightbox.getContext() || {};
  const { data = {}, onModalOpen, closeModal } = lightboxContext;
  const t = translations.t as TFunction;
  const timezone = wixCodeApi.site.timezone || DEFAULT_TIMEZONE;
  const locale = getSiteLocale(flowAPI);
  const priceFormatter = PriceFormattingConverter.createPriceFormatter(
    locale,
    getSiteCurrency(flowAPI)
  );
  const { metaSiteId = '' } = flowAPI.controllerConfig.platformAPIs.bi || {};
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;

  const itemModalController = new ItemModalController(
    $bindAll,
    $w,
    t,
    priceFormatter,
    window,
    isEditor,
    experiments,
    isMemberLoggedIn
  );

  const populatedMenuClient = PopulatedMenuClient({
    httpClient,
    experiments,
    msid: metaSiteId,
    currency: getSiteCurrency(flowAPI),
  });

  const getFirstItem = async (menus: PopulatedMenu[]) => {
    let firstItem;
    const menuWithItems = menus.find((menu) =>
      menu.sections.some((section) => section.itemIds?.length)
    );
    if (menuWithItems) {
      firstItem = await populatedMenuClient.getMenuFirstValidItem(menuWithItems);
    }

    return firstItem;
  };

  const getItem = async () => {
    const { item, canAcceptOrders, fedopsLogger } = data ?? {};
    if ((isEditor || isPreview) && !item) {
      try {
        const pageOperationIdPromise = getPageOperationId(
          flowAPI.controllerConfig.wixCodeApi.site,
          flowAPI.errorMonitor
        );

        const getCurrentOperationId = async () => {
          const operationsClient = new OperationsClient(httpClient);
          const operationsByGroup = await operationsClient.getOperationsByGroupId(
            pageOperationIdPromise
          );
          if (operationsByGroup?.length) {
            return (
              operationsByGroup.find((operation) => operation.locationDetails?.default)?.id ??
              operationsByGroup[0].id
            );
          }

          const operation = await operationsClient.getOperation(pageOperationIdPromise);
          return operation?.id;
        };

        const menuIdsByOperation = PopulateMenuIdsByOperationClient({
          httpClient,
          experiments,
          sentry: errorMonitor,
          fedopsLogger,
          timezoneOffset: getTimezoneOffset(timezone),
        }).getAll(getCurrentOperationId());

        const menus = await populatedMenuClient.getPartial(menuIdsByOperation);
        const firstItem = menus?.length ? await getFirstItem(menus) : undefined;
        return { item: firstItem, canAcceptOrders: true };
      } catch (err) {
        reportError(new Error(`Editor mode - fetch data for item modal error ${err}`));
      }
    }
    return { item, canAcceptOrders };
  };

  const processedItemDataPromise = getItem().then(async ({ item, canAcceptOrders }) => {
    const modifierGroups = convertToRepeaterModifierGroups(item.modifierGroups ?? []);
    return { item, canAcceptOrders, modifierGroups };
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);

      try {
        const { item, canAcceptOrders, modifierGroups } = await processedItemDataPromise;

        itemModalController.init(
          { ...data, item, canAcceptOrders },
          onModalOpen,
          closeModal,
          modifierGroups
        );
      } catch (e) {
        console.error(new Error(`fetch data failed ${e} ${isEditor} ${isPreview}`));
      }
    },
    exports: {},
  };
});
